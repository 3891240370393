
<template>
    <!-- <div v-if="s_instrumentsHistory">
      <span v-for="(d, index) in s_instrumentsHistory" v-bind:key="'s_i_1'+index">
        <span v-if="d.data">
            <span v-for="(d1, index1) in d.data" v-bind:key="'s_v_2'+index1">{{ d1.HIGH }} ,</span>
        </span>
      </span>
    </div> -->
    <div></div>
</template>

<script>

import { mapState } from 'vuex'

export default {
  name: 'vuexdata',
  data() {                                   
    return {
    }
  },
  computed: mapState([
    's_instrumentsHistory'
  ]),
  components: {     
  },
  methods: {   
  },
  beforeMount(){
    // console.log("------------beforeMount--------------")
    // console.log(this.s_instrumentsHistory);
    // console.log("-------------------------------------")
  },
  mounted() {
    console.log(this.s_instrumentsHistory);
  },
}
</script>